/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosStatic } from 'axios';
import type { State } from '../state/state.service';
import { ConfigService } from '../config/config.service';
import { authService } from '../app.module';
import BaseService from './base.services';
import { IUser } from '../types/railcarLoadingInterfaces';

export class UserRoleService extends BaseService {
  constructor(
    private readonly backend: AxiosStatic,
    private readonly config: ConfigService,
    private readonly state: State,
  ) {
    super(authService);
  }

  async getAllUsers(): Promise<IUser[]> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.get(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/user`,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      console.log(data);
      return data as IUser[];
    } catch (e: any) {
      this.state.error = e;
    }
    return [];
  }
}
