import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import OutageTableForm from './OutageTableForm';
import { state } from '../state/state.service';
import { ConfigService } from '../config/config.service';

const config = new ConfigService().from({}, { groups: ['browser'] });
export const EDIT_OUTAGE_TABLE_PAGE_ROUTE = '/EditOutageTable';

export function EditOutageTablePage(): JSX.Element {
  let outageTableId = 0;
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const addValidationMessage = (message: string) => {
    setValidationMessages([...validationMessages, message]);
  };
  const resetValidationMessages = () => {
    setValidationMessages([]);
  };
  if (state.selectedOutageTable && state.selectedOutageTable.length) {
    outageTableId = state.selectedOutageTable[0].OutageTableId;
  }
  const validationMessageList = validationMessages.length
    ? validationMessages.map((msg) => {
        return (
          <li className="text-danger text-center font-weight-bold small">
            {msg}
          </li>
        );
      })
    : null;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/TableAdmin' }}>
          <small className="font-italic font-weight-bold text-primary">
            Table Admin
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ViewOutageTable' }}>
          <small className="font-italic font-weight-bold text-primary">
            View/Edit/Delete Outage Table
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            Edit Outage Table
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1">
        ENID NH3 Rail - Edit Outage Table
      </p>
      {validationMessages.length > 0 && (
        <div className="d-flex justify-content-center ">
          <ul>{validationMessageList}</ul>
        </div>
      )}
      <OutageTableForm
        isEdit
        outageTableId={outageTableId}
        validationMessageFn={addValidationMessage}
        resetValidationMessages={resetValidationMessages}
        startingInchesValue={config.OUTAGE_TABLE_INCHES_MIN}
        endingInchesValue={config.OUTAGE_TABLE_INCHES_MAX}
      />
    </>
  );
}
