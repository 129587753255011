/* eslint-disable no-console */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { ConfigService } from '../config/config.service';
import { authService } from '../app.module';
import { state } from '../state/state.service';
import { RailCarService } from '../services/railcar.services';
import { DemoService, HealthCheck } from '../demo/demo.service';

export const HOME_PAGE_ROUTE = '/';
export const config = new ConfigService().from({}, { groups: ['browser'] });
export const HomePage = view(() => {
  // Local state for displaying form page or not authorized page
  const [authRefreshing, setAuthRefreshing] = useState(false);
  const [isUnAuthorized, setIsUnauthorized] = useState(true);
  const demoService = new DemoService(axios, authService, state, config);
  const railCarService = new RailCarService(axios, config, state);
  // On page load checks healthz endpoint to check validity of User's token
  // If call fails, load not authorized page since user doesn't have permission

  useEffect(() => {
    console.log('------------------------');
    console.log('Health check useEffect running');
    console.log('------------------------');
    const hasNonNullValue = (value?: string | null) =>
      !!(value && value !== '');
    // Define local function to implement async/await behavior
    async function getHealthCheck(userRole: string | undefined) {
      // Gets health of service to check User's permissions & token validity
      if (!hasNonNullValue(userRole)) {
        setAuthRefreshing(true);
        const healthResponse: {
          statusCode: number;
          health?: HealthCheck;
        } = await demoService.getHealth();
        console.log(healthResponse);
        if (healthResponse.statusCode === 200) {
          setAuthRefreshing(false);
          setIsUnauthorized(false);
          await railCarService.setUserRole();
        } else if (healthResponse.statusCode === 400) {
          setAuthRefreshing(false);
          setIsUnauthorized(true);
        }
      } else {
        setIsUnauthorized(false);
      }
    }
    getHealthCheck(state.userRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: '350px' }}
    >
      {authRefreshing && isUnAuthorized && (
        <Loader
          type="ThreeDots"
          color="#123966"
          height={50}
          width={60}
          timeout={8000} // 5 secs
        />
      )}
      {isUnAuthorized && !authRefreshing && (
        <p className="font-weight-bold text-primary text-center mt-1 small">
          User not logged in or unauthorized.
        </p>
      )}
      {!authRefreshing && state.userRole && (
        <div className="span2">
          <p>
            {state.userRole && state.userRole === 'admin' && (
              <Link
                to="/TableAdmin"
                className="btn btn-primary me-2 mr-4 btn-lg btn-block"
              >
                Car And Outage Table Admin
              </Link>
            )}
            {state.userRole && state.userRole === 'read-only' && (
              <Link
                to="/TableAdmin"
                className="btn btn-primary me-2 mr-4 btn-lg btn-block"
              >
                Car And Outage Table Listing
              </Link>
            )}
          </p>

          <p>
            <Link
              to="/Calulations"
              className="btn btn-primary me-2 btn-lg btn-block"
            >
              Outage Calculation
            </Link>
          </p>

          {state.userRole && state.userRole === 'admin' && (
            <p>
              <Link
                to="/RoleAdmin"
                className="btn btn-primary me-2 btn-lg btn-block"
              >
                Access Management
              </Link>
            </p>
          )}
        </div>
      )}
    </div>
  );
});
