/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { AxiosResponse, AxiosStatic } from 'axios';
import { User } from 'oidc-client';
import type { State } from '../state/state.service';
import type { AuthService } from '../authentication';
import { ConfigService } from '../config/config.service';

export interface HealthCheck {
  health: {
    status: string;
  };
  name: string;
  version: string;
}

export class DemoService {
  constructor(
    private readonly backend: AxiosStatic,
    private readonly authService: AuthService,
    private readonly state: State,
    private readonly config: ConfigService,
  ) {}

  async runAuth(): Promise<User> {
    const user = await this.authService.getUser();
    if (!user) {
      throw new Error('NotLoggedInError: User is not logged in');
    } else {
      this.state.user = user;
      return user;
    }
  }

  async getHealth(): Promise<{
    statusCode: number;
    health?: HealthCheck;
  }> {
    try {
      const user: User = await this.runAuth();

      const healthResponse: AxiosResponse<HealthCheck> = await this.backend.get(
        `${this.config.REACT_APP_BACKEND_API_URL}/healthz`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        },
      );

      return { statusCode: 200, health: healthResponse.data };
    } catch (err: any) {
      // this.state.error = err;
      console.warn(err);
      delete this.state.user;
      return { statusCode: 400 };
    }
  }
}
