/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-destructuring */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
// import { IUser } from 'src/types/railcarLoadingInterfaces';
import { config } from './Home';
import { state } from '../state/state.service';
import { UserRoleService } from '../services/userrole.services';

export const LIST_USERS_PAGE_ROUTE = '/ListUsers';

export const ListUsersPage = view(() => {
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  useEffect(() => {
    async function getUsers() {
      const userRoleService = new UserRoleService(axios, config, state);
      const result = await userRoleService.getAllUsers();
      state.userList = result;
    }
    getUsers();
  }, []);
  const users = state.userList;
  const showReasults = users && users.length > 0;
  if (users && users.length === 0) {
    setNoRecordsFound(true);
  }
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/RoleAdmin' }}>
          <small className="font-italic font-weight-bold text-primary">
            User Role Admin
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            List Users
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1 small">
        List of Users and Accesses
      </p>

      {noRecordsFound && (
        <p className="text-secondary text-center mt-1 small">
          No records found !
        </p>
      )}
      {showReasults && (
        <div className="d-flex justify-content-center ">
          <div className="col-md-7">
            <table className="table table-striped table-responsive-md btn-table">
              <thead>
                <tr>
                  <th className="font-weight-bold text-primary pl-4 small">
                    UserId
                  </th>
                  <th className="font-weight-bold text-primary small">Admin</th>
                  <th className="font-weight-bold text-primary small">
                    Read-Only
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((obj) => {
                  return (
                    <tr>
                      <td className="pl-3 font-weight-bold small">
                        {obj.userId}
                      </td>
                      {obj.isAdmin ? (
                        <td className="font-weight-bold small">&#10004;</td>
                      ) : (
                        <td className="font-weight-bold small">&times;</td>
                      )}
                      {obj.isReadOnly ? (
                        <td className="font-weight-bold small">&#10004;</td>
                      ) : (
                        <td className="font-weight-bold small">&times;</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
});
