/* eslint-disable no-restricted-globals */
import { ValidatedClass } from '@kaes/validated-class';
import {
  IsBoolean,
  IsFQDN,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsSemVer,
  IsString,
  IsUrl,
  Length,
  Max,
  Min,
} from 'class-validator';
import { name, repository, version } from '../../package.json';

export enum Group {
  DEPLOYMENT = 'deployment',
  BROWSER = 'browser',
}

export class ConfigService extends ValidatedClass {
  @IsIn(['production', 'development', 'test'])
  @IsOptional()
  @IsNotEmpty()
  NODE_ENV = 'development';

  @IsIn(['local', 'dev', 'uat', 'prod'])
  @IsOptional()
  ENVIRONMENT = 'local';

  @IsString()
  PACKAGE_NAME = name;

  @IsSemVer()
  PACKAGE_VERSION = version;

  @IsUrl({}, { groups: [Group.DEPLOYMENT] })
  REPOSITORY_URL = repository.url;

  @IsFQDN({}, { groups: [Group.DEPLOYMENT] })
  ROOT_DOMAIN: string;

  @IsString({ groups: [Group.DEPLOYMENT] })
  SUB_DOMAIN: string;

  @IsString({ groups: [Group.DEPLOYMENT] })
  @Length(12, 12, { groups: [Group.DEPLOYMENT] })
  AWS_ACCOUNT_ID: string;

  @IsString()
  @IsNotEmpty()
  DATADOG_APP_ID = 'd578a1af-b7d5-4b32-a022-5a9bc92c6af1';

  @IsString()
  @IsNotEmpty()
  DATADOG_CLIENT_TOKEN = 'pubda45fa0e66f36c6ecd26f4227233ecd7';

  @IsBoolean()
  DATADOG_TRACK_INTERACTIONS = true;

  @Min(1)
  @Max(100)
  @IsInt()
  DATADOG_SAMPLE_RATE = 100;

  @IsString()
  REACT_APP_OIDC_AUDIENCE =
    process.env.REACT_APP_OIDC_AUDIENCE ?? 'https://auth.kaes.io';

  @IsString()
  REACT_APP_OIDC_AUTHORITY =
    process.env.REACT_APP_OIDC_AUTHORITY ?? 'https://kaes.us.auth0.com';

  @IsString()
  REACT_APP_OIDC_CLIENT_ID =
    process.env.REACT_APP_OIDC_CLIENT_ID ?? 'N5D2kg7wsVLOOERZRvJaFTxzT25UtOHR';

  @IsInt()
  OUTAGE_TABLE_INCHES_MIN = 15;

  @IsInt()
  OUTAGE_TABLE_INCHES_MAX = 28;

  @IsString()
  REACT_APP_BACKEND_API_URL =
    process.env.REACT_APP_BACKEND_API_URL ??
    'https://eo92h5l854.execute-api.us-east-1.amazonaws.com/v1';
}
