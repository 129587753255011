/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { view } from '@risingstack/react-easy-state';
import { state } from '../state/state.service';
import { IOutageTableEntry } from '../types/railcarLoadingInterfaces';
import { config } from './Home';
import { OutageTableService } from '../services/outagetable.services';

interface OutageTableFormProps {
  isEdit: boolean;
  outageTableId: number;
  startingInchesValue: number;
  endingInchesValue: number;
  validationMessageFn: (message: string) => void;
  resetValidationMessages: () => void;
}

const OutageTableForm = ({
  isEdit,
  outageTableId,
  startingInchesValue,
  endingInchesValue,
  validationMessageFn,
  resetValidationMessages,
}: OutageTableFormProps): JSX.Element => {
  const [saving, setSaving] = useState(false);
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  let newOutageTable: Record<string, any>[] = [];
  if (!state.selectedOutageTable || !isEdit) {
    // Intialize empty outage table
    for (let num = startingInchesValue; num <= endingInchesValue; num += 1) {
      newOutageTable.push({
        OutageTableId: outageTableId,
        Inches: num,
        Gallons: 0,
      });
    }
  } else {
    newOutageTable = JSON.parse(JSON.stringify(state.selectedOutageTable));
  }

  const checkForUnacceptableGallonValues = (): OutageTableService | null => {
    const unacceptableGallonValue = newOutageTable.find((obj) => {
      return obj.Gallons <= 0;
    });
    if (unacceptableGallonValue) {
      validationMessageFn('Gallon values can not be zero or negative!');
      setSaving(false);
      setSaveSuccessful(false);
    } else if (state.selectedOutageTable) {
      return new OutageTableService(axios, config, state);
    }
    return null;
  };

  const outageTableCreateHandler = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    const service = checkForUnacceptableGallonValues();
    if (service && state.selectedOutageTable) {
      setSaving(true);
      const result = await service.saveOutageTable(state.selectedOutageTable);
      if (result != null) {
        setSaving(false);
        setSaveSuccessful(true);
      }
    }
  };

  const outageTableUpdateHandler = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    const service = checkForUnacceptableGallonValues();
    if (service && state.selectedOutageTable) {
      setSaving(true);
      const result = await service.updateOutageTable(state.selectedOutageTable);
      if (result != null) {
        setSaving(false);
        setSaveSuccessful(true);
      }
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventId = (event.target as HTMLElement).id;
    const splitted = eventId.split('|');
    newOutageTable[Number(splitted[2])].Gallons = event.target.value;
    state.selectedOutageTable = newOutageTable as IOutageTableEntry[];
    resetValidationMessages();
  };

  let submitButton = (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={outageTableCreateHandler}
    >
      Create
    </button>
  );
  if (isEdit) {
    submitButton = (
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={outageTableUpdateHandler}
      >
        Update
      </button>
    );
  }

  return (
    <>
      {saving && (
        <div className="d-flex justify-content-center ">
          <Loader
            type="ThreeDots"
            color="#123966"
            height={50}
            width={60}
            timeout={5000} // 5 secs
          />
        </div>
      )}
      {saveSuccessful && (
        <p className="text-success text-center mt-1 small">
          Data saved successfully !
        </p>
      )}
      <div className="d-flex justify-content-center ">
        <div className="col-md-4">
          <form>
            <table className="table table-responsive-md btn-table">
              <thead>
                <tr>
                  <th className="font-weight-bold text-primary small">
                    Outage Table ID
                  </th>
                  <th className="font-weight-bold text-primary small">
                    Inches
                  </th>
                  <th className="font-weight-bold text-primary small">
                    Gallons
                  </th>
                </tr>
              </thead>
              <tbody>
                {newOutageTable.length &&
                  newOutageTable.map((obj, index) => {
                    return (
                      <tr>
                        <td>
                          <input
                            type="number"
                            name={`${outageTableId}|${obj.Inches}`}
                            value={outageTableId || ''}
                            readOnly
                            className="form-control"
                            key={`${outageTableId}|${obj.Inches}`}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            name={String(obj.Inches)}
                            value={obj.Inches || ''}
                            readOnly
                            className="form-control"
                            key={String(obj.Inches)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            name={`${obj.Inches}|${outageTableId}`}
                            value={obj.Gallons || ''}
                            onChange={onChangeHandler}
                            className="form-control"
                            id={`${obj.Inches}|${outageTableId}|${index}`}
                            key={`${obj.Inches}|${outageTableId}`}
                          />
                        </td>
                      </tr>
                    );
                  })}
                <tr
                  className="text-center"
                  style={{ backgroundColor: '#FCFCFB' }}
                >
                  <td colSpan={3}>{submitButton}</td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </>
  );
};

export default view(OutageTableForm);
