import axios from 'axios';
import { UserManager } from 'oidc-client';
import { AuthService } from './authentication';
import { state } from './state/state.service';
import { ConfigService, Group } from './config/config.service';
import { DemoService } from './demo/demo.service';

export const configService = new ConfigService().from(process.env, {
  groups: [Group.BROWSER],
});

export const authService = new AuthService(
  new UserManager({
    authority: configService.REACT_APP_OIDC_AUTHORITY,
    client_id: configService.REACT_APP_OIDC_CLIENT_ID,
    response_type: 'code',
    scope: 'openid profile email',
    loadUserInfo: true,
    redirect_uri: window.origin,
    extraQueryParams: {
      audience: configService.REACT_APP_OIDC_AUDIENCE,
    },
  }),
  state,
);

export const demoService = new DemoService(
  axios,
  authService,
  state,
  configService,
);
