/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-console */

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { view } from '@risingstack/react-easy-state';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { state } from '../state/state.service';
import { IRailCar, IOutageTableEntry } from '../types/railcarLoadingInterfaces';
import { config } from './Home';
import { RailCarService } from '../services/railcar.services';
import { OutageTableService } from '../services/outagetable.services';
import { OutageTableSearchForm } from './OutageTableSearchForm';

interface CarFormProps {
  isEdit: boolean;
}

const schema = yup
  .object({
    Initial: yup
      .string()
      .uppercase('Car initial should be in uppercase')
      .min(4, 'Must be exactly 4 characters')
      .max(4, 'Must be exactly 4 characters')
      .required(),
    CarNumber: yup
      .number()
      .typeError('Car number must be a number')
      .positive()
      .integer()
      .required(),
    CarCapacity: yup
      .number()
      .typeError('Car capacity must be a number')
      .positive()
      .integer()
      .required(),
    LightWeight: yup
      .number()
      .typeError('Light weight value must be a number')
      .positive()
      .integer()
      .required(),
  })
  .required();

const CarForm = ({ isEdit }: CarFormProps): JSX.Element => {
  const [saving, setSaving] = useState(false);
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const [existingTable, setExistingTable] = useState(true);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [showEntries, setShowEntries] = useState(false);
  const [outageTableSearchResult, setOutageTableSearchResult] = useState<
    IOutageTableEntry[]
  >([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  if (!state.selectedRailCar) {
    const railCar: IRailCar = {
      Initial: '',
      CarNumber: 0,
      CarCapacity: 0,
      LightWeight: 0,
      OutageTableId: 0,
    };
    state.selectedRailCar = railCar;
  }
  const car: Record<string, any> = state.selectedRailCar;
  const addValidationMessage = (message: string) => {
    setValidationMessages([...validationMessages, message]);
  };

  const searchOutageTableHandler = async (outageTableId: number) => {
    console.log('Searching outage table for outageTableId', outageTableId);
    setValidationMessages([]);
    setOutageTableSearchResult([]);
    const outageTableService = new OutageTableService(axios, config, state);
    const result = await outageTableService.getOutageTable(outageTableId);
    if (result) {
      setOutageTableSearchResult(result);
    }
  };

  const resetValidationMessages = () => {
    setValidationMessages([]);
  };

  const newCarCreateHandler = async () => {
    if (!state.selectedRailCar?.OutageTableId) {
      addValidationMessage('Please select from the existing outage tables');
      return;
    }
    console.log('New Car', state.selectedRailCar);
    setSaving(true);
    if (state.selectedRailCar) {
      const railCarService = new RailCarService(axios, config, state);
      const result = await railCarService.saveRailCar(state.selectedRailCar);
      if (result) {
        setSaving(false);
        setSaveSuccessful(true);
      }
    }
  };

  const carUpdateHandler = async () => {
    if (!existingTable) {
      addValidationMessage('Please select from the existing outage tables');
      return;
    }
    console.log('Updated Car', state.selectedRailCar);
    setSaving(true);
    if (state.selectedRailCar) {
      const railCarService = new RailCarService(axios, config, state);
      const result = await railCarService.updateRailCar(state.selectedRailCar);
      if (result) {
        setSaving(false);
        setSaveSuccessful(true);
      }
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveSuccessful(false);
    car[event.target.name] = event.target.value;
  };

  const updateOutageTableIdForCar = (
    outagleTableId: string,
    selectedExisting: boolean,
  ) => {
    car['OutageTableId'] = Number(outagleTableId);

    setExistingTable(selectedExisting);
  };

  const handleClose = () => {
    setShowEntries(false);
  };

  const showTableEntries = async () => {
    console.log('Show Outage Table entries');
    const outageTableService = new OutageTableService(axios, config, state);
    if (state.selectedRailCar) {
      const result = await outageTableService.getOutageTable(
        state.selectedRailCar.OutageTableId,
      );
      if (result) {
        setOutageTableSearchResult(result);
      }
    }
    setShowEntries(true);
  };

  let submitButton = (
    <button
      type="button"
      className="btn btn-primary btn-sm"
      onClick={handleSubmit(newCarCreateHandler)}
    >
      Create
    </button>
  );
  if (isEdit) {
    submitButton = (
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={handleSubmit(carUpdateHandler)}
      >
        Update
      </button>
    );
  }
  const validationMessageList = validationMessages.length
    ? validationMessages.map((msg) => {
        return (
          <li className="text-danger text-center font-weight-bold small">
            {msg}
          </li>
        );
      })
    : null;
  return (
    <>
      {saving && (
        <div className="d-flex justify-content-center ">
          <Loader
            type="ThreeDots"
            color="#123966"
            height={50}
            width={60}
            timeout={5000} // 5 secs
          />
        </div>
      )}
      {saveSuccessful && (
        <p className="text-success text-center mt-1 small">
          Data saved successfully !
        </p>
      )}
      {validationMessages.length > 0 && (
        <div className="d-flex justify-content-center ">
          <ul>{validationMessageList}</ul>
        </div>
      )}
      <div className="d-flex justify-content-center ">
        <div className="col-md-4">
          <form className="card p-1">
            <div className="form-group mb-0">
              <label
                htmlFor="Initial"
                className="text-dark font-weight-bolder small"
              >
                Car Initial
              </label>
              <input
                type="text"
                {...register('Initial')}
                value={car.Initial}
                onChange={onChangeHandler}
                className="form-control"
                id="CarInitial"
              />
              <p className="text-danger small">{errors.Initial?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="CarNumber"
                className="text-dark font-weight-bolder small"
              >
                Car Number
              </label>
              <input
                type="number"
                value={car.CarNumber || ''}
                {...register('CarNumber')}
                onChange={onChangeHandler}
                className="form-control"
                id="CarNumber"
              />
              <p className="text-danger small">{errors.CarNumber?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="CarCapacity"
                className="text-dark font-weight-bolder small"
              >
                Car Capacity (Gallons)
              </label>
              <input
                type="number"
                value={car.CarCapacity || ''}
                {...register('CarCapacity')}
                onChange={onChangeHandler}
                className="form-control"
                id="CarCapacity"
              />
              <p className="text-danger small">{errors.CarCapacity?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="LightWeight"
                className="text-dark font-weight-bolder small"
              >
                LightWeight (Pounds)
              </label>
              <input
                type="number"
                value={car.LightWeight || ''}
                {...register('LightWeight')}
                onChange={onChangeHandler}
                className="form-control"
                id="LightWeight"
              />
              <p className="text-danger small">{errors.LightWeight?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="OutageTableId"
                className="text-dark font-weight-bolder small"
              >
                Outage Table Id
              </label>
              <OutageTableSearchForm
                searchOutageTableHandler={searchOutageTableHandler}
                resetValidationMessages={resetValidationMessages}
                initVal={car.OutageTableId || ''}
                updateCar={updateOutageTableIdForCar}
              />
              {isEdit && existingTable && (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={showTableEntries}
                >
                  <small className="font-italic text-muted">
                    Outage Table Entries
                  </small>
                </button>
              )}
            </div>

            <div className="text-center pt-1">{submitButton}</div>
          </form>
        </div>
      </div>

      <Modal show={showEntries} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Outage Table Entries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '80%', margin: '0 auto' }}>
            <table className="table table-striped table-responsive-md btn-table">
              <thead>
                <tr>
                  <th className="font-weight-bold text-primary small">
                    Outage Table ID
                  </th>
                  <th className="font-weight-bold text-primary small">
                    Inches
                  </th>
                  <th className="font-weight-bold text-primary small">
                    Gallons
                  </th>
                </tr>
              </thead>
              <tbody>
                {outageTableSearchResult.map((obj) => {
                  return (
                    <tr>
                      <td>{obj.OutageTableId}</td>
                      <td>{obj.Inches}</td>
                      <td>{obj.Gallons}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default view(CarForm);
