/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosStatic } from 'axios';
import type { State } from '../state/state.service';
import { ConfigService } from '../config/config.service';
import { authService } from '../app.module';
import BaseService from './base.services';
import { IUserRole } from '../types/railcarLoadingInterfaces';

export class RoleService extends BaseService {
  constructor(
    private readonly backend: AxiosStatic,
    private readonly config: ConfigService,
    private readonly state: State,
  ) {
    super(authService);
  }

  async assignRole(
    userId: string,
    roleName: string,
  ): Promise<IUserRole | null> {
    try {
      const subjectName = `oauth2|KochID|${userId}`;
      const user = await this.checkLoginStatus();
      const roleParams: {
        subjectName: string;
        roleName: string;
      } = {
        subjectName,
        roleName,
      };

      const { data } = await this.backend.post(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/user-role/assign`,
        roleParams,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      console.log(data);
      return data as IUserRole;
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async unassignRole(
    userId: string,
    roleName: string,
  ): Promise<IUserRole | null> {
    try {
      const subjectName = `oauth2|KochID|${userId}`;
      const user = await this.checkLoginStatus();
      const roleParams: {
        subjectName: string;
        roleName: string;
      } = {
        subjectName,
        roleName,
      };

      const { data } = await this.backend.post(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/user-role/unassign`,
        roleParams,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      console.log(data);
      return data as IUserRole;
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }
}
