/* eslint-disable no-console */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { state } from '../state/state.service';
import { IOutageTableEntry } from '../types/railcarLoadingInterfaces';
import { config } from './Home';
import { OutageTableService } from '../services/outagetable.services';
import { OutageTableSearchForm } from './OutageTableSearchForm';
import { EDIT_OUTAGE_TABLE_PAGE_ROUTE } from './EditOutageTablePage';

export const VIEW_OUTAGE_TABLE_PAGE_ROUTE = '/ViewOutageTable';

export function ViewOutageTablePage(): JSX.Element {
  const [outageTableSearchResult, setOutageTableSearchResult] = useState<
    IOutageTableEntry[]
  >([]);
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [searching, setSearching] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const searchOutageTableHandler = async (outageTableId: number) => {
    console.log('Searching outage table for outageTableId', outageTableId);
    setSearching(true);
    setNoRecordsFound(false);
    setDeleteSuccessful(false);
    setValidationMessages([]);
    setOutageTableSearchResult([]);
    if (outageTableId === 0) {
      setValidationMessages(['Outage Table ID is required!']);
      setSearching(false);
    } else {
      const outageTableService = new OutageTableService(axios, config, state);
      const result = await outageTableService.getOutageTable(outageTableId);
      if (result) {
        setOutageTableSearchResult(result);
      } else {
        setNoRecordsFound(true);
      }
      setSearching(false);
    }
  };

  const updateSelectedOutageTable = () => {
    state.selectedOutageTable = outageTableSearchResult;
  };

  const confirmDelete = () => {
    updateSelectedOutageTable();
    setAskConfirmation(true);
  };
  const handleDelete = async () => {
    setSearching(true);
    const outageTableService = new OutageTableService(axios, config, state);
    if (state.selectedOutageTable) {
      const result = await outageTableService.deleteOutageTable(
        state.selectedOutageTable[0].OutageTableId,
      );
      if (result) {
        setDeleteSuccessful(true);
        setOutageTableSearchResult([]);
      }
      setSearching(false);
    }
    setAskConfirmation(false);
  };
  const handleClose = () => {
    setAskConfirmation(false);
  };

  const resetValidationMessages = () => {
    setValidationMessages([]);
  };
  const showReasults = !searching && outageTableSearchResult.length > 0;
  const validationMessageList = validationMessages.length
    ? validationMessages.map((msg) => {
        return (
          <li className="text-danger text-center font-weight-bold small">
            {msg}
          </li>
        );
      })
    : null;
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/TableAdmin' }}>
          <small className="font-italic font-weight-bold text-primary">
            Table Admin
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            View/Edit/Delete Outage Table
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1">
        ENID NH3 Rail - Find Outage Table
      </p>
      <div className="d-flex justify-content-center ">
        <OutageTableSearchForm
          searchOutageTableHandler={searchOutageTableHandler}
          resetValidationMessages={resetValidationMessages}
          initVal="Outage Table ID ..."
        />
      </div>
      {validationMessages.length > 0 && (
        <div className="d-flex justify-content-center ">
          <ul>{validationMessageList}</ul>
        </div>
      )}
      {searching && (
        <div className="d-flex justify-content-center ">
          <Loader
            type="ThreeDots"
            color="#123966"
            height={50}
            width={60}
            timeout={5000} // 5 secs
          />
        </div>
      )}
      {deleteSuccessful && (
        <p className="text-success text-center mt-1 small">
          Outage table deleted !
        </p>
      )}
      {noRecordsFound && (
        <p className="text-secondary text-center mt-1 small">
          No records found !
        </p>
      )}
      {showReasults && (
        <div style={{ width: '80%', margin: '0 auto' }}>
          <table className="table table-striped table-responsive-md btn-table">
            <thead>
              <tr>
                <th className="font-weight-bold text-primary">
                  Outage Table ID
                </th>
                <th className="font-weight-bold text-primary">Inches</th>
                <th className="font-weight-bold text-primary">Gallons</th>
              </tr>
            </thead>
            <tbody>
              {outageTableSearchResult.map((obj) => {
                return (
                  <tr>
                    <td className="text-sm-left font-weight-bold text-gray">
                      {obj.OutageTableId}
                    </td>
                    <td className="text-sm-left font-weight-bold text-gray">
                      {obj.Inches}
                    </td>
                    <td className="text-sm-left font-weight-bold text-gray">
                      {obj.Gallons}
                    </td>
                  </tr>
                );
              })}
              {state.userRole && state.userRole === 'admin' && (
                <tr
                  className="text-center"
                  style={{ backgroundColor: '#FCFCFB' }}
                >
                  <td colSpan={3}>
                    <Link
                      to={EDIT_OUTAGE_TABLE_PAGE_ROUTE}
                      key="Update-Outage-Table"
                      onClick={updateSelectedOutageTable}
                      className="btn btn-primary btn-sm m-0 waves-effect"
                    >
                      Edit
                    </Link>
                    <button
                      type="button"
                      key="Delete-Outage-Table"
                      onClick={confirmDelete}
                      className="btn btn-primary btn-sm ml-1 waves-effect"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      ;
      <Modal show={askConfirmation} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete Outage Table (ID{' '}
          {state.selectedOutageTable
            ? state.selectedOutageTable[0].OutageTableId
            : 0}{' '}
          ) ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
