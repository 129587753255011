/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosStatic } from 'axios';
import type { State } from '../state/state.service';
import { ConfigService } from '../config/config.service';
import { authService } from '../app.module';
import BaseService from './base.services';
import { IRailCar } from '../types/railcarLoadingInterfaces';

export class RailCarService extends BaseService {
  constructor(
    private readonly backend: AxiosStatic,
    private readonly config: ConfigService,
    private readonly state: State,
  ) {
    super(authService);
  }

  async getRailCars(
    carInitial: string,
    carNumber?: number,
  ): Promise<IRailCar[] | null> {
    try {
      const user = await this.checkLoginStatus();
      const searchParams: { carInitial: string; carNumber?: number } = {
        carInitial,
      };
      if (carNumber) {
        searchParams.carNumber = carNumber;
      }

      const { data } = await this.backend.get(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/rail-car`,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
          params: searchParams,
        },
      );
      if (data.statusCode === 200) {
        return JSON.parse(data.body) as IRailCar[];
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async saveRailCar(railCar: IRailCar): Promise<string | null> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.post(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/rail-car`,
        railCar,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      if (data.statusCode === 200) {
        return data.body;
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async updateRailCar(railCar: IRailCar): Promise<string | null> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.put(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/rail-car`,
        railCar,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      if (data.statusCode === 200) {
        return data.body;
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async deleteRailCar(
    carInitial: string,
    carNumber: number,
  ): Promise<string | null> {
    try {
      const user = await this.checkLoginStatus();
      const deleteParams: { carInitial: string; carNumber: number } = {
        carInitial,
        carNumber,
      };
      const { data } = await this.backend.delete(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/rail-car`,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
          params: deleteParams,
        },
      );
      if (data.statusCode === 200) {
        return data.body;
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async setUserRole(): Promise<string | null> {
    try {
      const user = await this.checkLoginStatus();
      await this.backend.delete(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/check-permission`,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      console.log('User has admin role');
      this.state.userRole = 'admin';
    } catch (e: any) {
      if (e.message.includes('403')) {
        console.log('User has read-only role');
        this.state.userRole = 'read-only';
      }
    }
    return null;
  }
}
